<template>
    <div class="client-page">
        <div class="searchOpen">
            <search
                style="margin-top:10px"
                :is-customer="true"
                :label-arr="labelArr"
                @clickData="clickData"
            />
        </div>
        <el-card class="main" :style="{ 'margin-top': '10px' }">
            <!-- 搜索区域 -->
            <div class="search flex-sb">
                <div class="flex">
                    <el-button
                        type="warning"
                        size="mini"
                        :disabled="client_Ids.length == 0"
                        @click="transfer(0)"
                    >转移</el-button>
                    <el-button
                        style="margin-right:10px"
                        type="danger"
                        size="mini"
                        :disabled="client_Ids.length == 0"
                        @click="dropPublicSea(0)"
                    >丢公海</el-button>
                    <el-button
                        class="bulkCall"
                        type="primary"
                        size="mini"
                        style="margin-right:10px"
                        @click="bulkCall"
                    >批量呼叫</el-button>
                    <div class="top-search">
                        <el-input
                            v-model="status.keyword"
                            clearable
                            size="small"
                            placeholder="请输入姓名/手机号"
                            @keypress.native.enter="getTableData()"
                            @clear="getTableData()"
                        />
                        <!-- <i class="el-icon-search"></i> -->
                        <div class="icon-area" @click="getInputData()">
                            <img width="20" height="20" src="@/assets/common/search.png" alt />
                        </div>
                    </div>
                </div>

                <div class="flex right-time">
                    <div>流入公海时间</div>
                    <div>
                        <el-radio-group v-model="timeTabs" size="mini" @change="changeTime">
                            <el-radio-button label="0">今天</el-radio-button>
                            <el-radio-button label="1">明天</el-radio-button>
                            <el-radio-button label="2">后天</el-radio-button>
                        </el-radio-group>
                    </div>
                    <img
                        width="30"
                        height="30"
                        src="@/assets/customer/showHeadIcon.svg"
                        alt
                        @click="showOrHidden"
                    />
                </div>
            </div>
            <!-- 提示弹框 -->
            <trace-info ref="traceInfo" />
            <!-- 表格区域 -->
            <el-table
                ref="multipleTable"
                v-loading="loading"
                :data="tableData"
                border
                style="width: 100%;margin-top:10px"
                :height="tableHeight(332)"
                @selection-change="handleSelectionChange"
            >
                <el-table-column type="selection" fixed />
                <el-table-column fixed label="序号" type="index" width="55" />
                <el-table-column fixed label="重要" width="55">
                    <template slot-scope="scope">
                        <i
                            class="iconfont icon-aixin xin"
                            :class="{ ping: scope.row.important == 1 }"
                            @click="zhongyao(scope.row)"
                        />
                    </template>
                </el-table-column>
                <span v-for="(item, index) in colData" :key="index">
                    <el-table-column
                        v-if="item.selected == 1"
                        :label="item.columnName"
                        :prop="item.propertyName"
                        :min-width=" item.propertyName == 'quality' || item.propertyName == 'qualityLevel' ? '190px': item.propertyName == 'category' || item.propertyName == 'age' ? '100px' : item.propertyName == 'followLogList'? '500px' : item.width"
                    >
                        <template slot-scope="scope">
                            <span v-if="item.propertyName == 'quality'">
                                <span
                                    v-if="scope.row.hasHouse == 1"
                                    class="icon house"
                                    style="margin-right: 10px"
                                >房</span>
                                <span
                                    v-if="scope.row.hasVehicle == 1"
                                    class="icon car"
                                    style="margin-right: 10px"
                                >车</span>
                                <span
                                    v-if="scope.row.hasShebao == 1"
                                    style="margin-right: 10px"
                                    class="icon shebao"
                                >社</span>
                                <span
                                    v-if="scope.row.hasGongjijin == 1"
                                    class="icon gongjijin"
                                    style="margin-right: 10px"
                                >金</span>
                                <span
                                    v-if="scope.row.hasBaodan == 1"
                                    style="margin-right: 10px"
                                    class="icon baodan"
                                >保</span>
                            </span>
                            <span v-else-if="item.propertyName == 'category'">
                                <img
                                    v-if="scope.row.category == 1"
                                    width="55"
                                    src="@/assets/customer/companySea.png"
                                    alt
                                />
                                <img
                                    v-if="scope.row.category == 0"
                                    width="55"
                                    src="@/assets/customer/departmentSea.png"
                                    alt
                                />
                            </span>
                            <span v-else-if="item.propertyName == 'followLogList'">
                                <span slot="reference" @click="showDetail(scope.row)">
                                    <span
                                        v-if="scope.row.followLogList.length > 0"
                                        class="show-content"
                                    >{{ scope.row.followLogList[0].modifyContent }}</span>
                                    <span
                                        v-if="scope.row.followLogList[0].modifyContent == ''"
                                    >点击查看跟进信息</span>
                                </span>
                            </span>
                            <el-rate
                                v-else-if="item.propertyName == 'qualityLevel'"
                                v-model="scope.row.qualityLevel"
                                disabled
                                text-color="#ff9900"
                            />
                            <!-- propertyName: "fundNeeds" -->
                            <span
                                v-else-if="item.propertyName == 'fundNeeds'"
                            >{{ scope.row.fundNeeds || scope.row.fundNeeds == 0 ? scope.row.fundNeeds + "万" : "" }}</span>
                            <span
                                v-else-if="item.propertyName == 'age'"
                            >{{ scope.row.age || scope.row.age === 0 ? scope.row.age + "岁" : "未知" }}</span>
                            <span v-else>{{ scope.row[scope.column.property] }}</span>
                        </template>
                    </el-table-column>
                </span>
                <el-table-column fixed="right" label="操作" min-width="230">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="followPage(scope.row)">跟进</el-button>
                        <el-button type="warning" size="mini" @click="transfer(1, scope.row)">转移</el-button>

                        <!-- @onConfirm="delData"
                        @onConfirm="cancelData"-->
                        <el-popconfirm
                            v-if="scope.row.important == 1"
                            cancel-button-text="取消"
                            confirm-button-text="确定"
                            placement="bottom"
                            icon="el-icon-info"
                            icon-color="#f7b534"
                            title="此客户为重要客户，确定丢入公海吗?"
                            @onConfirm="dropPublicSea(1, scope.row)"
                            @onCancel="cancelThrow"
                        >
                            <el-button
                                slot="reference"
                                type="danger"
                                size="mini"
                                style="margin-left:10px"
                            >丢公海</el-button>
                        </el-popconfirm>
                        <el-button
                            v-else
                            type="danger"
                            size="mini"
                            @click="dropPublicSea(1, scope.row)"
                        >丢公海</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="block" style="margin-top: 10px; text-align: right">
                <el-pagination
                    :current-page="page"
                    :page-size="size"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="len"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </div>
            <assign-dialog
                :title="title"
                :show-dialog.sync="showDialog"
                @submitPartment="submitPartment"
            />
            <show-or-hidden
                ref="showOrHidden"
                :show-dialog.sync="showCol"
                @submitShowOrHidden="submitShowOrHidden"
            />
            <!-- <add-client-dialog :showDialog.sync="showAddDialog"></add-client-dialog> -->
            <to-follow-clue-dialog
                ref="toCuleFollow"
                :show.sync="show"
                @followCluePage="followCluePage"
            />
        </el-card>
        <turn-call-dialog
            :show-turn-call-dialog.sync="showTurnCallDialog"
            @useNewList="useNewList"
            @useOldList="useOldList"
        />
    </div>
</template>

<script>
import {
    clients_expire,
    client_ordinary,
    client_important,
    client_owner,
    client_public,
    client_clue_unfollowed,
} from '@/api/my_client';
import { columnShow, showOrHiddenCol } from '@/api/public_content';
import { getYMDHMS } from '@/assets/js/time.js';
import elementResizeDetectorMaker from 'element-resize-detector';
import commonAlert from '@/mixins/commonAlert.js';
import clientBulkCall from '@/mixins/clientBulkCall.js';
import resize from '@/mixins/resize';
export default {
    components: {
        search: () => import('@/components/templates/search-open'),
        traceInfo: () => import('@/components/templates/trace-info'),
        assignDialog: () => import('../components/assignDialog'),
        showOrHidden: () => import('../components/showOrHidden'),
        toFollowClueDialog: () => import('../components/toFollowClue'),
    },
    mixins: [clientBulkCall, commonAlert, resize],
    data() {
        return {
            pageType: 0, //他是一个静态属性，用于帮助混淆识别页面，如果你不知道他的真实作用，请不要更改他
            labelArr: [
                '客户资质',
                '资金需求',
                '跟进阶段',
                '获得方式',
                '意向度',
                '资质星级',
            ],
            status: {
                keyword: '', //input搜索关键词
                fundNeedsScopeType: '', //资金需求
                departmentId: '', //归属部门ID
                qualityType: '', //资质类型
                followStage: '',
                getCategory: '',
                startTime: '', //开始时间
                endTime: '', //结束时间
                orderBy: '', //排序方式
                qualityLevel: '', //星级
                purposeLevel: '', //资质等级
                pageNo: 1, //页码数
                pageSize: 10, //数据量
                expiredWithInTime: 1,
            },
            page: 1,
            len: 0,
            size: 10,
            loading: true,
            valuetime: '', //选择转为时间值
            timeTabs: 0, // 时间选中
            // tableHeight: window.innerHeight - 320,
            tableData: [], //数据列表
            colData: [],
            client_Ids: [], //表格选中的CheckBox存放客户id值
            showDialog: false, //展示选择部门
            showCol: false, //
            type: '',
            singleClientId: [],
            showAddDialog: false,
            title: '选择要转移的对象',
            show: false,
            searchOpenHeight: '',
            onRiseHeight: window.innerHeight - 330,
        };
    },
    watch: {
        /**
         * 监控表格的数据data，自动设置表格宽度
         * @param valArr
         */
        // 控制表格长度
        tableData(valArr) {
            const _this = this;
            this.colData = this.colData.map((value) => {
                const arr = valArr.map((x) => {
                    if (value.propertyName == 'followLogList') {
                        return x[value.propertyName][
                            x[value.propertyName].length - 1
                        ].modifyContent;
                    } else {
                        return x[value.propertyName];
                    }
                }); // 获取每一列的所有数据
                arr.push(value.columnName); // 把每列的表头也加进去算
                value.width = _this.getMaxLength(arr) + 20; // 每列内容最大的宽度 + 表格的内间距(依据实际情况而定)
                return value;
            });
        },
    },
    created() {
        // window.addEventListener("resize", this.getHeight);
        // window.addEventListener('resize', this.watchSize);
        this.getColData();
    },
    mounted() {
        // this.watchSize();
        this.getTableData();
    },
    destroyed() {
        // window.addEventListener('resize', this.watchSize);
        // window.removeEventListener('resize', this.getHeight);
    },
    methods: {
        // 批量呼叫
        bulkCall() {
            if (this.callServices.isDisabled()) {
                this.alertCallServicesNotOpen();
                return;
            }
            this.getMyClientCallList();
        },
        // watchSize() {
        //     const _this = this;
        //     const erd = elementResizeDetectorMaker({
        //         strategy: 'scroll',
        //     });
        //     erd.listenTo(
        //         document.getElementsByClassName('searchOpen'),
        //         (element) => {
        //             let height = element.offsetHeight;
        //             _this.$nextTick(() => {
        //                 _this.searchOpenHeight = height - 50;
        //                 //监听到事件后执行的业务逻辑
        //                 // _this.searchOpenHeight = height - 50;
        //                 // console.log(_this.tableHeight - height -50 + 'px');
        //                 // _this.tableHeight = _this.tableHeight - height - 50 + "px";
        //                 // console.log(_this.tableHeight);
        //             });
        //         }
        //     );
        // },
        // getHeight() {
        //     // this.tableHeight = this.tableData.length > 0 ? window.innerHeight - 250 : "";
        //     this.tableHeight = window.innerHeight - 320;
        // },
        // 根据内容控制表格长度
        getMaxLength(arr) {
            return arr.reduce((acc, item) => {
                if (item) {
                    let calcLen = this.getTextWidth(item);
                    if (acc < calcLen) {
                        acc = calcLen;
                    }
                }
                return acc;
            }, 0);
        },
        // 根据内容控制表格长度
        getTextWidth(str) {
            let width = 0;
            let html = document.createElement('span');
            html.innerText = str;
            html.className = 'getTextWidth';
            document.querySelector('body').appendChild(html);
            width = document.querySelector('.getTextWidth').offsetWidth;
            document.querySelector('.getTextWidth').remove();
            return width;
        },
        handleSelectionChange(val) {
            this.client_Ids = val;
        },
        // 获取表头数据
        getColData() {
            // 获取列显隐字段
            let queryCol = {
                tableName: 'expireClientTable',
                defaultValue: 0,
            };
            columnShow(queryCol).then((res) => {
                if (res.code == 0) {
                    this.colData = res.data.list;
                } else {
                    this.$message.error(res.message);
                }
            });
        },

        // 点击搜索获取数据
        getInputData() {
            this.status.pageNo = 1;
            console.log(this.status.pageNo, 'this.status.pageNo');
            this.page = 1;
            this.getTableData();
        },

        // 获取数据
        getTableData() {
            // 获取列表数据
            clients_expire(this.status).then((res) => {
                // this.loading = true
                if (res.code == 0) {
                    this.tableData = res.data.list;
                    this.len = res.data.total;
                    this.tableData.forEach((item) => {
                        item.createdTime = getYMDHMS(item.createdTime);
                        item.lastFollowedTime = getYMDHMS(
                            item.lastFollowedTime
                        );
                        item.lastTransferredTime = getYMDHMS(
                            item.lastTransferredTime
                        );
                        // 跟进阶段
                        let followStage = this.common.allValueNeed(
                            'client-follow-stage'
                        );
                        followStage.forEach((i) => {
                            if (item.followStage == i.number) {
                                item.followStage = i.cnVal;
                            }
                        });
                        // 性别
                        let clientSex = this.common.allValueNeed('sex');
                        clientSex.forEach((i) => {
                            if (item.sex == i.number) {
                                item.sex = i.cnVal;
                            }
                        });
                        // 获得方式
                        let clientGet = this.common.allValueNeed(
                            'client-get-category'
                        );
                        clientGet.forEach((every) => {
                            if (item.getCategory == every.number) {
                                item.getCategory = every.cnVal;
                            }
                        });
                        // 意向度
                        let purposeLevel =
                            this.common.allValueNeed('loan-purpose-level');
                        purposeLevel.forEach((x) => {
                            if (item.purposeLevel == x.number) {
                                item.purposeLevel = x.cnVal;
                            }
                        });
                    });

                    setTimeout(() => {
                        this.loading = false;
                    }, 500);
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 点击后拿到的数据
        clickData(val) {
            this.status.purposeLevel = val.purposeLevel;
            this.status.qualityType = val.qualityType;
            this.status.fundNeedsScopeType = val.fundNeedsScopeType;
            this.status.qualityLevel = val.qualityLevel;
            this.status.followStage = val.followStage;
            this.status.departmentId = val.departSeaVal;
            this.status.orderBy = val.customerOrderBy;
            this.status.getCategory = val.getCategory;
            this.getTableData();
        },
        // 选择时间筛选
        changeTime() {
            if (this.timeTabs == 0) this.status.expiredWithInTime = 1;
            if (this.timeTabs == 1) this.status.expiredWithInTime = 2;
            if (this.timeTabs == 2) this.status.expiredWithInTime = 3;
            this.status.pageNo = 1;
            this.page = 1;
            this.getTableData();
        },
        //分页
        handleSizeChange(val) {
            this.size = val;
            this.status.pageSize = this.size;
            this.getTableData();
        },
        handleCurrentChange(val) {
            this.page = val;
            this.status.pageNo = this.page;
            this.getTableData();
        },
        showOrHidden() {
            this.showCol = true;
            this.$refs.showOrHidden.init(this.colData);
        },
        showDetail(info) {
            this.$refs.traceInfo.showDetail(info);
        },
        // 列显隐提交按钮方法
        submitShowOrHidden(val) {
            let data = [];
            // 传回来的是要隐藏的值 这里做的是把隐藏的筛选掉传回后端是显示的值
            let newArr = this.colData.filter(
                (item) => !val.some((ele) => item.columnName == ele)
            );
            newArr.forEach((item) => {
                data.push(item.columnName);
            });
            showOrHiddenCol({
                tableName: 'expireClientTable',
                selectedColumns: data,
            }).then((res) => {
                if (res.code == 0) {
                    this.showCol = false;
                    this.getTableData();
                    this.getColData();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // // 添加客户
        // addNewClient() {
        //   this.showAddDialog = true;
        // },
        //客户状态更变
        zhongyao(row) {
            if (row.important == 1) {
                client_ordinary({
                    clientId: row.id,
                }).then((res) => {
                    if (res.code == 0) {
                        this.$message.success('成功转为普通客户');
                        this.getTableData();
                    } else {
                        this.$message.error(res.error);
                    }
                });
            } else {
                client_important({
                    clientId: row.id,
                }).then((res) => {
                    if (res.code == 0) {
                        // this.$notify({
                        //   title: "操作成功",
                        //   message: "已转为重要客户！",
                        //   type: "success",
                        // });
                        this.$message.success('成功转为重要客户');
                        this.getTableData();
                    } else {
                        this.$message.error(res.error);
                    }
                });
            }
        },
        transfer(type, row) {
            this.showDialog = true;
            this.type = type;
            if (type == 1) {
                this.client_Ids = [];
                this.$refs.multipleTable.clearSelection();
                this.singleClientId = [row];
            }
        },
        dropPublicSea(type, row) {
            let clientArr = [];
            if (type == 0) {
                clientArr = this.client_Ids.map((x) => x.id);
            } else {
                clientArr = [row.id];
            }
            client_public({
                clientIds: clientArr,
            }).then((res) => {
                if (res.code == 0) {
                    this.$message.success('已丢入公海');
                    this.getTableData();
                    this.cancelThrow();
                } else {
                    this.$message.error(res.error);
                }
            });
        },
        cancelThrow() {
            this.client_Ids = [];
            this.singleClientId = [];
            this.$refs.multipleTable.clearSelection();
        },
        // 转移选项
        submitPartment(val) {
            let newClientIds;
            if (this.type == 1) {
                newClientIds = this.singleClientId.map((x) => x.id);
            } else {
                newClientIds = this.client_Ids.map((x) => x.id);
            }
            client_owner({
                clientIds: newClientIds,
                inStaffId: val,
            }).then((res) => {
                if (res.code == 0) {
                    this.$message({
                        message: '已将客户转移',
                        type: 'success',
                    });
                    this.showDialog = false;
                    this.client_Ids = [];
                    this.$refs.multipleTable.clearSelection();
                    this.singleClientId = [];
                    this.getTableData();
                } else {
                    this.$message.error('参数不可为空');
                }
            });
        },
        // 跟进
        followPage(row) {
            // this.$emit("toFollowPage", row);
            client_clue_unfollowed({
                clientId: row.id,
            }).then((res) => {
                console.log(res);
                if (res.code == 0) {
                    if (res.data.clueId) {
                        this.show = true;
                        this.$nextTick(() => {
                            this.$refs.toCuleFollow.init(res.data.clueId);
                        });
                    } else {
                        this.$emit('toFollowPage', row);
                    }
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 去往线索跟进页面
        followCluePage(data) {
            // console.log('这是所有客户页面',data);
            this.$emit('followCluePage', data);
        },
    },
};
</script>

<style lang="less" scoped>
@import '@/css/table.less';

.flex {
    display: flex;
    align-items: center;
    .bulkCall {
        transform: translateX(-8px);
    }
}

/deep/ .el-table thead tr th {
    background: rgba(245, 247, 249, 1);
    color: #333;
}

.flex-sb {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.client-page {
    padding-left: 20px;
    padding-right: 20px;
    height: 100%;

    .main {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.search {
    .top-search {
        position: relative;
        width: 55%;
        min-width: 160px;

        /deep/ .el-input__clear {
            margin-right: 23px;
        }

        .icon-area {
            position: absolute;
            right: 5px;
            top: 5px;
            border-left: 1px solid #ccc;
            padding-left: 5px;
            cursor: pointer;
        }
    }

    .regive {
        background-color: rgb(252, 178, 44);
        color: #fff;

        &:hover {
            background-color: rgb(253, 201, 108);
        }

        &:active {
            background-color: rgb(222, 153, 20);
            border-color: #fff;
        }
    }

    .right-time {
        div:not(:last-child) {
            margin-right: 10px;
        }

        /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
            color: #4086ec;
            background-color: #ecf3fd;
            box-shadow: 0 0px 0px #ccc;
            border-color: #dcdfe6;
        }

        /deep/ .el-radio-button__inner {
            color: #aaa;
        }
    }
}

.regive {
    background-color: rgb(252, 178, 44);
    color: #fff;

    &:hover {
        background-color: rgb(253, 201, 108);
    }

    &:active {
        background-color: rgb(222, 153, 20);
        border-color: #fff;
    }
}

.regive-normal {
    color: rgb(222, 153, 20);
    background-color: #fff;
    border: 1px solid rgb(222, 153, 20);
}

.xin {
    font-size: 30px;
    vertical-align: middle;
    color: #999;
    cursor: pointer;
}

.xin:hover {
    color: #ffafb8;
}

.xin:active {
    color: #e96e7d;
}

.ping {
    color: #ff8190;
}

/deep/ .el-popconfirm__main {
    margin: 10px 0;
}

/deep/ .el-radio-button--mini .el-radio-button__inner {
    padding: 7px 10px;
}

/deep/ .el-table thead tr th {
    background: rgba(245, 247, 249, 1);
    color: #909399;
}

.show-content {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
</style>
